import React from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useMedia } from '../../../hooks/useMedia'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { deleteMedia } from '../../../../api/app/media'
import NavBar from '../../atoms/NavBar'
import { HStack, Spinner } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import { Box, Heading, Container, Text } from '@chakra-ui/react'
import Card from '../../atoms/Card'
import FooterBase from '../../molecules/FooterBase'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import UploaderCover from '../../organisms/Uppy/src/UploaderCover'
const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/heic', 'image/heif']

const Controls = () => {
	const { uuid, type } = useParams()
	const navigate = useNavigate()
	const location = useLocation()

	const handleNavigation = () => {
		if (type === 'invitation') {
			navigate(location.state?.backDestination || '/event/' + uuid, {
				state: {
					isReviewMode: location.state?.isReviewMode,
				},
			})
		} else {
			navigate('/event/' + uuid + '/customize')
		}
	}

	return (
		<NavBar>
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={handleNavigation} />
			</HStack>
		</NavBar>
	)
}

const UploadCoverPhoto = () => {
	useScrollTopOnMount()
	const { uuid, type } = useParams()
	const { event } = useEventContext()
	const media = useMedia()
	const recipients = useEventRecipients()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const data = {
		invitation: {
			heading: 'Upload Cover Photo',
			content: `Choose a photo of ${recipients.formatted} — everyone you invite to submit will see this cover photo, choose a good one!`,
		},
		theme: {
			heading: 'Upload Your Logo',
			content: `Replace the VidDay logo in the intro with your logo.`,
		},
	}

	const handleClose = () => {
		const path = type == 'theme' ? `/event/${uuid}/customize` : location.state?.backDestination || '/event/' + uuid
		navigate(path)
	}

	const updateCoverPhoto = async () => {
		// delete old cover photo before updating to new one
		const currentCoverPhotoId = event.coverPhotoId
		if (currentCoverPhotoId && media.length > 0) {
			const coverPhotoMedia = media.find((m) => {
				if (m.id === currentCoverPhotoId || m.mediaUuid === currentCoverPhotoId) return true
				return false
			})
			if (coverPhotoMedia && coverPhotoMedia.id) {
				await dispatch(deleteMedia(coverPhotoMedia.id, false, true))
			}
		}
	}

	const updatethemeCover = async () => {
		// delete old cover photo before updating to new one
		const currentThemeCoverId = event.themeCoverId
		if (currentThemeCoverId && media.length > 0) {
			const themeCoverMedia = media.find((m) => {
				if (m.id === currentThemeCoverId || m.mediaUuid === currentThemeCoverId) return true
				return false
			})
			if (themeCoverMedia && themeCoverMedia.id) {
				await dispatch(deleteMedia(themeCoverMedia.id, false, true))
			}
		}
	}

	if (event.id) {
		return (
			<>
				<Controls />

				<Container maxW="container.md" py="1rem" variant="main">
					<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
						<Heading size="lg" variant="hero">
							{data[type].heading}
						</Heading>

						<Text fontSize="md">{data[type].content}</Text>
					</Box>

					<Card
						alignItems="center"
						variant="main"
						mb="2rem"
						textAlign="center"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						{/** Uploader for the cover photo */}
						{type == 'invitation' && (
							<UploaderCover
								event={event}
								allowedFileTypes={allowedFileTypes}
								handleClose={handleClose}
								maxNumberOfFiles={1}
								handleCoverPhoto={updateCoverPhoto}
							/>
						)}

						{/** Uploader for the theme cover */}
						{type == 'theme' && (
							<UploaderCover
								event={event}
								allowedFileTypes={allowedFileTypes}
								handleClose={handleClose}
								maxNumberOfFiles={1}
								handleThemeCover={updatethemeCover}
							/>
						)}
					</Card>

					<FooterBase />
				</Container>
			</>
		)
	}

	return <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
}

export default UploadCoverPhoto
