import React, { useLayoutEffect, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import loadable from '@loadable/component'
import { Box, Button, Flex, Image } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { pinturaEdit, setMediaToOccupied } from '../../../../../../api/app/media'
import { Spinner } from '@chakra-ui/react'
import '@pqina/pintura/pintura.css'
// import from pintura
import {
	// plugins
	setPlugins,
	plugin_crop,
	plugin_finetune,
	plugin_filter,
	plugin_sticker,
	plugin_annotate,
} from '@pqina/pintura'

const ImageCompareSlider = loadable(
	() => import(/* webpackPrefetch: true */ '../../atoms/image-comparison-slider/index.tsx'),
	{ ssr: false }
)

/** Pintura */
import { PinturaEditorModal } from '@pqina/react-pintura'

/* polyfills */
import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).

import 'md-gum-polyfill'
import ResizeObserver from 'resize-observer-polyfill'

// window.ResizeObserver ??= ResizeObserver

if (window.ResizeObserver === undefined || window.ResizeObserver === null) {
	window.ResizeObserver = ResizeObserver
}

/* UPPY */
import Uppy from '@uppy/core'
import { uppy as uppyConfig } from '../../../../../../../client/config.client'
import Tus from '@uppy/tus'

// Turn src into url for use in image tag
const toURL = (src) => {
	return src instanceof Blob ? URL.createObjectURL(src) : src
}
import { editorDefaults } from './config'
export { editorDefaults }

let imgStyles = {}

const ImageCropper = ({ item, isEditMode, setEditMode }) => {
	const imageRef = useRef(null)
	const [src, setSrc] = useState(item.signedLocationUrl)

	const [loading, setLoading] = useState(true)

	const [placeholderStyles, setPlaceholderStyles] = useState({
		position: 'relative',
		maxHeight: '386px',
		maxWidth: '100%',
		minH: '120px',
		minW: '120px',
	})

	// use different styles depending on image size and whether mobile or not
	// helps make images much larger on both desktop and mobile in a responsive way.
	if (item.sourceHeight < item.sourceWidth && isMobile) {
		imgStyles = {
			maxWidth: '100%',
			maxH: '28vh',
			margin: 'auto',
			cursor: 'pointer',
		}
	} else if (item.sourceHeight > item.sourceWidth && isMobile) {
		imgStyles = {
			maxWidth: '100%',
			maxH: '64vh',
			minH: '48vh',
			margin: 'auto',
			cursor: 'pointer',
		}
	} else {
		imgStyles = {
			maxWidth: '100%',
			maxH: ['48vh', '64vh'],
			margin: 'auto',
			cursor: 'pointer',
		}
	}

	useEffect(() => {
		setLoading(true)
		if (item && !item.occupied) {
			setSrc(item.signedLocationUrl)
		}
	}, [item])

	useLayoutEffect(() => {
		if (imageRef && imageRef.current) {
			if (imageRef.current.offsetWidth != 0) {
				const newStyles = {
					...placeholderStyles,
					height: imageRef.current.offsetHeight,
					width: imageRef.current.offsetWidth,
				}
				setPlaceholderStyles(newStyles)
			}
		}
	}, [item])

	const imageLoaded = () => {
		setLoading(false)
	}

	const handleSetSrc = (val) => {
		// We don't want to update the source with a blob because we want to retrieve
		//the value from the server after the media is no longer occupied.
		setSrc('') // Otherwise, set this to `val`
		setLoading(true)
	}

	/**
	 * Handle when closing Pintura
	 * */
	const handleClose = () => {
		setEditMode(false)
	}

	if (isEditMode) {
		return <PinturaEdit source={src} setSource={handleSetSrc} image={item} onClose={handleClose} />
	}

	return (
		<>
			<Box position="relative">
				{loading && (
					<Box w="full" h="full" position="absolute">
						<Flex
							minHeight={['30vh', '38vh']}
							h="full"
							w="full"
							alignItems="center"
							justifyContent="center">
							<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="lg" />
						</Flex>
					</Box>
				)}
				{item.jobId?.split(':')[0] == 'nero' && item.upscaledKey ? (
					<ImageCompareSlider item={item} />
				) : (
					<Image
						ref={imageRef}
						src={src}
						onClick={() => setEditMode(!isEditMode)}
						sx={imgStyles}
						crossOrigin="anonymous"
						alt="Loading..."
						visibility={loading ? 'hidden' : 'visible'}
						loading="lazy"
						onLoad={imageLoaded}
						onContextMenu={(e) => {
							// Prevent "right-click" to save-as
							e.preventDefault()
						}}
					/>
				)}
			</Box>
		</>
	)
}

const PinturaEdit = ({ image, onClose, source, setSource }) => {
	const dispatch = useDispatch()
	const tusEndpoint = uppyConfig.tusEndpoint

	const [originalImage, setOriginalImage] = useState(image)

	/**
	 * Uppy Widget Initialization
	 */
	const uppy = new Uppy({
		id: 'uppy',
		debug: true,
		autoProceed: true,
		allowMultipleUploads: false,
	})
		.use(Tus, {
			endpoint: tusEndpoint,
			removeFingerprintOnSuccess: true, // do not keep references to previously uplaoded files in local storage
		})
		.on('file-added', (file) => {
			// console.log('Added file', file)
			setMediaToOccupied({ id: image.id })
		})
		.on('upload', (data) => {
			// console.log(`Starting upload Upload ID:${data.id} for files ${data.fileIDs}`)
		})
		.on('complete', (results) => {
			if (results.failed.length > 0) {
				// console.error('Failed files:', results.failed)
			}
			if (results.successful.length > 0) {
				// console.log("UPLOAD COMPLETE:",results.successful)
				// Map out media data to update location and thumb
				const source = results.successful[0]
				var payload = {
					id: image.id,
					data: {
						uploadKey: `${uppyConfig.tusPrefix}${source.uploadURL.split('/files/')[1].split('+')[0]}`,
						occupied: true,
					},
				}
				dispatch(pinturaEdit(payload))
			}
		})
		.on('error', (error) => {
			console.error('UPPY ERROR', error)
		})

	/**
	 * Confirm file editing
	 * @param {*} output
	 */
	const handlePinturaConfirm = (output) => {
		let name = unescape(originalImage.location)
			.replace(
				/https:\/\/media.vidday.com\/|https:\/\/media-staging.vidday.com\/|https:\/\/media-dev.vidday.com\/|https:\/\/s3.us-west-2.amazonaws.com\/vidday.v2.media.production\/|https:\/\/s3.us-west-2.amazonaws.com\/vidday.v2.media.staging\/|https:\/\/s3.us-west-2.amazonaws.com\/vidday.v2.media.dev\/|_normalized/gi,
				''
			)
			.replace(/\.[^/.]+$/, '')

		// setVisible(false)
		onClose()
		setSource(toURL(output))

		// build file with new name
		var file = { name: `${name}_normalized.jpg`, type: 'image/jpeg', data: output, meta: { use: 'source' } }
		uppy.addFile(file)
	}

	setPlugins(plugin_crop, plugin_filter, plugin_finetune, plugin_annotate, plugin_sticker)

	return (
		<PinturaEditorModal
			{...editorDefaults}
			src={source}
			// onLoad={(res) => console.log('load Pintura modal image', res)}
			// onHide={handlePinturaCancel}
			onHide={onClose}
			onProcess={({ dest }) => handlePinturaConfirm(dest)}
		/>
	)
}

export default ImageCropper
