import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Box } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import PinturaEditor from './PinturaEditor'
import configClient from '../../../../../../client/config.client'

/* polyfills */
import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'
import ResizeObserver from 'resize-observer-polyfill'
if (window.ResizeObserver === undefined || window.ResizeObserver === null) {
	window.ResizeObserver = ResizeObserver
}

/** Import Uppy Libraries and React-specific components */
import { Dashboard } from '@uppy/react'

/** Import Vidday Libs */
import { logGTMTracking, formatBaseFile, getCleanFileName } from '../utils'
import { postMedia } from '../../../../../api/app/media'
import { addFlashMessage } from '../../../../../api/app/behaviors/flash-messages'

/** Import Custom Hooks */
import useUppy from '../hooks/useUppy'
import useContributorId from '../hooks/useContributorId'
import useGroupMediaUUID from '../hooks/useGroupMediaUUID'
import usePlugins from '../hooks/usePlugins'

/** Import Styles (including those related to each Plugins) */
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './uppy.overwrite.css'

const screenMessage = ''

const allowedPlugins = ['GoogleDrive', 'Dropbox', 'Url']

const UploaderCover = ({
	// The maximum amount of files that can be uploaded
	maxNumberOfFiles,
	allowedFileTypes,
	handleClose,
	event,
	onUpload,
	// Specific to cover photo and theme cover
	handleCoverPhoto,
	handleThemeCover,
}) => {
	/** Pintura-related */
	const [pinturaFile, setPinturaFile] = useState(null)

	const { id, uuid } = event
	const dispatch = useDispatch()
	const contributorId = useContributorId()
	const groupMediaUuid = useGroupMediaUUID()

	const plugins = usePlugins(allowedPlugins, allowedPlugins)

	const uppy = useUppy(uuid, {
		id: 'upload-coverphoto',
		autoProceed: true,
		restrictions: {
			maxNumberOfFiles: maxNumberOfFiles,
			allowedFileTypes: allowedFileTypes,
		},
		meta: {
			eventId: id,
			eventUuid: uuid,
			uploadUuid: groupMediaUuid,
		},
		onBeforeFileAdded: (currentFile, files) => {
			/** Obtain a copy of the file where spaces have been stripped */
			const modifiedFile = getCleanFileName(currentFile)

			if (!allowedFileTypes.includes(modifiedFile.type)) {
				uppy.info(
					`Sorry we can't process ${modifiedFile.type} file-type. Please use JPG/PNG files.`,
					'error',
					6000
				)
				return false
			} else if (modifiedFile.meta?.handledByPintura) {
				return modifiedFile
			} else if (!modifiedFile.meta?.handledByPintura) {
				let file = modifiedFile.data

				if (modifiedFile.isRemote) {
					return modifiedFile
				} else {
					/** Set pintura file to edit */
					setPinturaFile(file)
					/* reject file because we need to show pintura and crop it first */
					return false
				}
			} else {
				return false
			}
		},
	})

	/**
	 * Method to handle the Store Upload
	 * @param {*} file
	 */
	const handleStoreUpload = (file, response) => {
		let upload = formatBaseFile(file, event)
		/** Attach the contributor ID */
		upload.contributorId = contributorId

		/** Check if this is TUS upload */
		if (response.uploadURL) {
			const uploadId = response.uploadURL.split('/files/')[1]?.split('+')[0] // upload id is portion between "/files/" and "+"
			upload.uploadKey = `${configClient.uppy.tusPrefix}${uploadId}` // tus upload key
		}

		/** If we passed a cover photo handler,
		 *  then it's a cover photo file */
		if (handleCoverPhoto) {
			upload.isCoverPhoto = true
		}

		/** If we passed a cover photo handler,
		 * then it's a  Theme cover */
		if (handleThemeCover) {
			upload.isThemeCover = true
		}

		/** Post media */
		dispatch(postMedia(upload, false)) // new Media Model, add to reducer [uploadsCompleted]
	}

	useEffect(() => {
		if (uppy) {
			uppy.on('file-removed', (file, reason) => {
				if (reason == 'removed-by-user') {
					console.log('File :', file.id, ' removed by user.')
				}
			})

			uppy.on('upload', ({ id, fileIDs }) => {
				// data object consists of `id` with upload ID and `fileIDs` array
				// with file IDs in current upload
				uppy.log(`Starting upload ${id} for files ${fileIDs}`)

				// Callback event to let the rest of the app we're currently uploading
				onUpload && onUpload(true)
			})

			uppy.on('upload-success', (data, response) => {
				/* Detect 0 byte files */
				if (data.size > 0 || data.isRemote == true) {
					/* Detected non-zero byte file, proceed */
					handleStoreUpload(data, response) // Add file to database and begin processing

					if (handleCoverPhoto) {
						handleCoverPhoto()
					}

					if (handleThemeCover) {
						handleThemeCover()
					}

					uppy.removeFile(data.id)
					uppy.info(`${data.data.name || data.name} - uploaded!`, 'success', 6000)

					// GTM Tracking
					// What media are we handling most?
					logGTMTracking(data)
				} else {
					/* Detected 0 byte file, reject */
					const error = new Error('Uploaded file is 0 bytes. Please retry.')
					// Emit error for this file
					uppy.emit('upload-error', data, error, response)
				}

				// Should we shut things down?
				let remainingFiles = uppy.getFiles()
				if (remainingFiles.length <= 0) {
					handleClose()
				}
			})

			uppy.on('error', (err) => {
				// When entire upload fails
				uppy.log('on ERROR : An error occured :', 'error')
				console.error(`on ERROR : An error occured : ${err.message}`)
			})

			uppy.on('upload-error', (file, error, response) => {
				// Fired each time a single upload has errored.
				console.error('Error with file : ', file.id)
				console.error('Error reason : ', error)
				console.error('Error Response from Companion : ', response)

				// Do other things here, notify user, highlight failed file in red, etc..
				//dispatch(addFlashMessage('error', `${file.data.name} - failed.`)) // notify the user this one is safe
			})
		}

		return () => uppy.close({ reason: 'unmount' })
	}, [uppy])

	return (
		<Box w="full">
			<Dashboard
				uppy={uppy}
				plugins={plugins}
				locale={{
					strings: {
						// Used as the label for the link that opens the system file selection dialog.
						browse: isMobile ? 'CAMERA OR LIBRARY' : 'SELECT FROM COMPUTER',
						myDevice: isMobile ? `Camera or Files` : `Upload`,
						done: 'Cancel',
						// Text to show on the droppable area.
						dropPasteFiles: screenMessage,
						dropPasteFolders: screenMessage,
						dropPasteBoth: screenMessage,
						dropPasteImportFiles: screenMessage,
						dropPasteImportFolders: screenMessage,
						dropPasteImportBoth: screenMessage,
						dropHint: screenMessage,
						dropHereOr: screenMessage,
						dropPaste: screenMessage,
						dropPasteImport: screenMessage,
					},
				}}
			/>

			<PinturaEditor
				uppy={uppy}
				pinturaFile={pinturaFile}
				setPinturaFile={setPinturaFile}
				isThemeCover={handleThemeCover != undefined ? true : false}
			/>
		</Box>
	)
}

UploaderCover.defaultProps = {
	maxNumberOfFiles: 1,
	allowedFileTypes: ['image/*'],
	handleClose: undefined,
	event: undefined,
	onUpload: undefined,
}

UploaderCover.propTypes = {
	maxNumberOfFiles: PropTypes.number,
	handleClose: PropTypes.func.isRequired,
	onUpload: PropTypes.func,
	event: PropTypes.object.isRequired,
}

export default UploaderCover
