import {
	// editor
	locale_en_gb,
	createDefaultImageReader,
	createDefaultImageWriter,

	// plugins
	setPlugins,
	plugin_crop,
	plugin_crop_locale_en_gb,
	// plugin_crop_defaults,
	plugin_finetune,
	plugin_finetune_locale_en_gb,
	plugin_finetune_defaults,
	plugin_filter,
	plugin_filter_locale_en_gb,
	plugin_filter_defaults,

	// plugin_decorate,
	// plugin_decorate_defaults,
	// plugin_decorate_locale_en_gb,
	plugin_sticker,
	plugin_sticker_locale_en_gb,
	plugin_annotate,
	plugin_annotate_locale_en_gb,
	markup_editor_defaults,
	markup_editor_locale_en_gb,
	blobToFile,
} from '@pqina/pintura'

import heic2any from 'heic2any'

export const editorDefaults = {
	imageCropLimitToImage: false,
	sizeMin: { width: 20, height: 20 },
	outputQuality: 100,
	cropResizeMatchImageAspectRatio: 'always',
	cropLimitToImageBounds: true,
	cropAllowInstructionZoom: true,

	imageReader: createDefaultImageReader({
		preprocessImageFile: async (file, options, onprogress) => {
			// If is not of type HEIC we skip the file
			if (!/heic|heif/.test(file.type)) return file
			try {
				// Let's turn the HEIC image into JPEG so the browser can read it
				const blob = await heic2any({
					blob: file,
					toType: 'image/jpeg',
					quality: 0.94,
				})
				// The editor expects a File so let's convert our Blob
				return blobToFile(blob, file.name)
			} catch (err) {
				console.log('heic convert error', err)
			}
		},
	}),
	imageWriter: createDefaultImageWriter(),

	// ...plugin_crop_defaults,
	...plugin_crop,
	...plugin_filter_defaults,
	...plugin_finetune_defaults,
	...markup_editor_defaults,
	locale: {
		...locale_en_gb,
		...plugin_crop_locale_en_gb,
		...plugin_filter_locale_en_gb,
		...plugin_finetune_locale_en_gb,
		...plugin_sticker_locale_en_gb,
		...plugin_annotate_locale_en_gb,
		...markup_editor_locale_en_gb,
	},
	// Advanced Sticker configuration
	stickers: [
		'🎁',
		'🎈',
		'🎊',
		'🎉',
		'💕',
		'💗',
		'💖',
		'💘',
		'💝',
		'🥳',
		'😷',
		'😀',
		'😁',
		'😆',
		'😂',
		'🤣',
		'😇',
		'🙂',
		'😉',
		'😍',
		'🥰',
		'😘',
		'🤗',
		'😜',
		'🤪',
		'🤓',
		'😎',
		'🤠',
		'🤩',
		'😱',
		'🥵',
		'😡',
		'🤢',
		'🤮',
		'🤧',
		'🤭',
		'🤫',
		'😬',
		'🙄',
		'🤤',
		'😴',
		'😵',
		'🤐',
		'💩',
		'🙌',
		'👍',
		'👎',
		'👊',
		'🤞',
		'🤟',
		'👈',
		'👉',
		'👆',
		'👇',
		'✋',
		'🤚',
		'💪',
		'👻',
		'👀',
		'🧡',
		'💛',
		'💚',
		'💙',
		'💜',
		'🖤',
		'🤍',
		'💄',
		'💋',
		'👄',
		'💅',
		'💃',
		'🕺',
		'👙',
		'👗',
		'👖',
		'👕',
		'👚',
		'🥼',
		'🦺',
		'🥼',
		'🧥',
		'🩳',
		'🩲',
		'🩱',
		'🥻',
		'👘',
		'🎩',
		'👒',
		'🎓',
		'⛑',
		'👑',
		'👓',
		'🕶',
		'🥽',
		'🐶',
		'🐭',
		'🐹',
		'🐰',
		'🦊',
		'🐻',
		'🐼',
		'🐨',
		'🐯',
		'🦁',
		'🐮',
		'🐷',
		'🐸',
		'🐵',
		'🙈',
		'🙉',
		'🙊',
		'🐣',
		'🐥',
		'🦄',
		'🦋',
		'🦕',
		'🦖',
		'🦎',
		'🐢',
		'🐙',
		'🐡',
		'🐠',
		'🐳',
		'🐬',
		'🍄',
		'💐',
		'🌷',
		'🌹',
		'🌺',
		'🌸',
		'🌼',
		'🌎',
		'🌍',
		'🌏',
		'💥',
		'🔥',
		'🌈',
		'⛄️',
		'🍔',
		'🎂',
		'🧁',
		'🍰',
		'🍺',
		'🍻',
		'🥂',
		'🍷',
		'🍸',
		'🍹',
		'🍾',
		'⚽️',
		'🏀',
		'🏈',
		'⚾️',
		'🥎',
		'🎾',
		'🏐',
		'🥏',
		'🎱',
		'🏓',
		'🏸',
		'🏒',
		'🏑',
		'🥍',
		'🏏',
		'⛳️',
		'🎣',
		'🛹',
		'🛷',
		'⛸',
		'🥌',
		'🎿',
		'🏂',
		'⛷',
		'🏆',
		'🥇',
		'🥈',
		'🥉',
		'🎬',
		'🎤',
	],
}
